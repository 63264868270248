@if (calendar) {
  <div class="flex flex-col lg:flex-row gap-8">
    @for (month of calendar; track month.month; let first = $first; let last = $last) {
      <div class="grow">
        <div class="mt-2 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-2 xl:col-start-9">
          <div class="flex items-center text-gray-900">
            @if (first && month.month.isAfter(notBefore(), 'month')) {
              <button type="button"
                      class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                      (click)="changeReference(-1)">
                <span class="sr-only">Previous month</span>
                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                        d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                        clip-rule="evenodd"/>
                </svg>
              </button>
            }
            <div
              class="flex-auto text-sm font-semibold">{{ 'datePicker.month.' + month.month.month() | translate }} {{ month.month.year() }}
            </div>
            @if (last && month.month.isBefore(notAfter(), 'month')) {
              <button type="button"
                      class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                      (click)="changeReference(1)">
                <span class="sr-only">Next month</span>
                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                        clip-rule="evenodd"/>
                </svg>
              </button>
            }
          </div>
          <div class="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
            <div>{{ 'datePicker.week.1' | translate }}</div>
            <div>{{ 'datePicker.week.2' | translate }}</div>
            <div>{{ 'datePicker.week.3' | translate }}</div>
            <div>{{ 'datePicker.week.4' | translate }}</div>
            <div>{{ 'datePicker.week.5' | translate }}</div>
            <div>{{ 'datePicker.week.6' | translate }}</div>
            <div>{{ 'datePicker.week.0' | translate }}</div>
          </div>
          <div class="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
            @for (day of month.days; track day; let idx = $index) {
              <button
                type="button"
                class="py-1.5 hover:enabled:bg-gray-100 focus:z-10 disabled:bg-gray-100 disabled:text-gray-500 disabled:no-underline"
                [disabled]="day.isBefore(notBefore(), 'day') || day.isAfter(notAfter(), 'day')"
                [ngClass]="classes(day, month, idx)"
                (click)="select(day)">
                <time [dateTime]="day.format('YYYY-MM-DD')"
                      class="mx-auto flex h-7 w-7 items-center justify-center">&nbsp;&nbsp;{{ day.date() }}&nbsp;&nbsp;
                </time>
              </button>
            }
          </div>
        </div>
      </div>
    }
  </div>
}

<!-- TailWind Doc
button:
 * Always include: "py-1.5 hover:bg-gray-100 focus:z-10"
 * Is current month, include: "bg-white"
 * Is not current month, include: "bg-gray-50"
 * Is selected or is today, include: "font-semibold"
 * Is selected, include: "text-white"
 * Is not selected, is not today, and is current month, include: "text-gray-900"
 * Is not selected, is not today, and is not current month, include: "text-gray-400"
 * Is today and is not selected, include: "text-indigo-600"

 * Top left day, include: "rounded-tl-lg"
 * Top right day, include: "rounded-tr-lg"
 * Bottom left day, include: "rounded-bl-lg"
 * Bottom right day, include: "rounded-br-lg"

time:
  * Always include: "mx-auto flex h-7 w-7 items-center justify-center rounded-full"
  * Is selected and is today, include: "bg-indigo-600"
  * Is selected and is not today, include: "bg-gray-900"
-->
